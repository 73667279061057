import React, { useState } from 'react'
import { Link, navigate } from "gatsby";
import Layout from '../../../components/layout';
import tickicon from '../../../assets/images/tick-box-icon.png';

function ConfirmationSuccess() {
  return (
    <Layout setTitle="Pricing">
        <div className="pricing-confirmation-success">
          <div className="card-section">
            <div className="card-body">
              <img src={tickicon} alt="tickicon" />
            </div>
            <h1>Success</h1> 
            <p>We have received your payment;<br/>we'll be in touch shortly!</p>
          </div>
          <div className="text-bottom">
             To check the payment details, Please  
             <Link to="/user-profile" state={{ orderHistory: "true" }}><span class="button-span">  Click here!</span></Link>
          </div>
        </div>
    </Layout>
  )
}

export default ConfirmationSuccess